import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionHero/SectionHero2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderProductCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/images/HIW1img.png");
;
import(/* webpackMode: "eager" */ "/app/src/images/HIW2img.png");
;
import(/* webpackMode: "eager" */ "/app/src/images/HIW3img.png");
;
import(/* webpackMode: "eager" */ "/app/src/images/HIW4img.png");
;
import(/* webpackMode: "eager" */ "/app/src/images/VectorHIW.svg");
