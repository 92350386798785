"use client";

import Heading from "@/components/Heading/Heading";
import { Skeleton } from "@/components/ui/skeleton";
import React, { FC, useEffect, useId, useRef, useState } from "react";

// @ts-ignore
import Glide from "@glidejs/glide/dist/glide.esm";
import ProductCard from "./ProductCard";
import Product from "@/models/Products";

export interface SectionSliderProductCardProps {
  heading?: string;
  data?: any[];
  // data?: Product[];
  className?: string;
  subHeading?: string;
  itemClassName?: string;
  headingFontClassName?: string;
  headingClassName?: string;
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  heading,
  subHeading = "À ne pas manquer",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const loadLatestArticles = () => {
    setIsLoading(true);
    Product.index()
      .then((res: any) => {
        setIsLoading(false);
        console.log(res);
        setProducts(
          res.data.products.filter((r: any) => r.images.length).slice(0, 4)
        );
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadLatestArticles();
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          <Heading rightDescText={subHeading}>
            {heading || `Nouveautés`}
          </Heading>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {products.map((item, index) => (
              <div key={index}>
                <ProductCard data={item} />
              </div>
            ))}
          </div>
        </div>
      )}

      {isLoading && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {[1, 2, 2, 2].map((item, index) => (
            <div key={index} className="w-full flex flex-col space-y-3">
              <Skeleton className="h-[260px] w-full rounded-xl" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SectionSliderProductCard;
